
import { BDialogLogin } from '@/components/biz'
import { Options, Vue } from 'vue-class-component'

@Options({
	components: {
		BDialogLogin,
	},
})
export default class Home extends Vue {}
